:root {
  /* --primaryColor: #A7B3A5;
  --primaryHover: #758473;
  --secondaryColor: #B56357;
  --paragraphColor: rgb(127, 127, 175);
  --BgColor: #EAE3EA;
  --formColor: #c199c1;
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem; */
  --primaryColor: #A7B3A5;
  --primaryHover: #758473; 
  --secondaryColor: #B56357; 
  --paragraphColor: rgb(175, 131, 127);
  --BgColor: #EAE3EA; 
  --formColor: #c199c1; 
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem;
}

.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--headerHeight);
  padding: 2rem;
  z-index: 1000;
  /* margin-top: 5%; */
}
.header .logoDiv {
  /* color: var(--textColor); */
  /* font-weight: 600; */
  /* margin-top: 15%; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 5%;
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header .navBar {
    background: var(--projectCardColor);
    position: absolute;
    height: -moz-max-content;
    height: max-content;
    width: 60%;
    border-radius: 1rem;
    padding: 1rem;
    /* top: 100%; */
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
    z-index: 1000;
    display: none;
  }
  .header .navBar .navLists {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding: 1rem 0;
  }
  .header .navBar .navLists .navItem {
    padding: 1rem 0;
  }
  .header .navBar .navLists .navItem .navLink {
    color: var(--textColor);
    font-size: 1.2rem;
    font-weight: 600;
  }
  .header .navBar .navLists .navItem .navLink:hover {
    color: var(--primaryColor);
  }
  .header .navBar .navLists .navItem .navLink .headerNumber {
    color: var(--primaryHover);
  }
  .header .navBar .navLists .btn {
    margin-top: 1rem;
  }
  .header .navBar .navLists .btn a {
    color: var(--textColor);
  }
  .header .navBar .closeNavBar {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }
  .header .navBar .closeNavBar:hover .icon {
    color: var(--primaryColor);
  }
  .header .activeNavBar {
    top: 5rem;
    /* top: 100%; */
    display: block;
    
  }
}

.activeHeader {
  background-color: var(--projectCardColor);
  box-shadow: 0 2px 4px rgba(1, 15, 29, 0.904);
}

@media screen and (min-width: 769px) {
  .toggleNavBar, .closeNavBar {
    display: none;
  }
  .header {
    padding: 2.5rem 2rem;
  }
  .header .navBar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0 1rem;
  }
  .header .navBar .navItem {
    padding: 0 1rem;
    display: inline;
  }
  .header .navBar .navItem .navLink {
    color: var(--textColor);
    font-size: 1.2rem;
    font-weight: 600;
  }
  .header .navBar .navItem .navLink:hover {
    color: var(--primaryColor);
  }
  .header .navBar .navItem .navLink .headerNumber {
    color: var(--primaryHover);
  }
  .header .navBar .btn {
    margin-left: 1rem;
  }
  .header .navBar .btn a {
    color: var(--primaryColor);
  }
}
@media screen and (min-width: 960px) {
  .header {
    padding: 2.5rem 5rem;
  }
  .header .navBar .navLists {
    padding: 0 2rem;
  }
  .header .navBar .navLists .navItem {
    padding: 0 2rem;
  }
}/*# sourceMappingURL=Navbar.css.map */





/* 
@media screen and (max-width: 768px) {
  .header .navBar {
    background: var(--projectCardColor);
    position: absolute;
    height: -moz-max-content;
    height: max-content;
    width: 80%;
    border-radius: 1rem;
    padding: 1rem;
    top: -100rem;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
    z-index: 1000;
  }
  .header .navBar .navLists {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding: 1rem 0;
  }
  .header .navBar .navLists .navItem {
    padding: 1rem 0;
  }
  .header .navBar .navLists .navItem .navLink {
    color: var(--textColor);
    font-size: 1.2rem;
    font-weight: 600;
  }
  .header .navBar .navLists .navItem .navLink:hover {
    color: var(--primaryColor);
  }
  .header .navBar .navLists .navItem .navLink .headerNumber {
    color: var(--primaryHover);
  }
  .header .navBar .navLists .btn {
    margin-top: 1rem;
  }
  .header .navBar .navLists .btn a {
    color: var(--textColor);
  }
  .header .navBar .closeNavBar {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }
  .header .navBar .closeNavBar:hover .icon {
    color: var(--primaryColor);
  }
  .header .activeNavBar {
    top: 6rem;
  }
}

.activeHeader {
  background-color: var(--projectCardColor);
  box-shadow: 0 2px 4px rgba(1, 15, 29, 0.904);
} */