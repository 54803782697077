:root {
  /* --primaryColor: #A7B3A5;
  --primaryHover: #758473;
  --secondaryColor: #B56357;
  --paragraphColor: rgb(127, 127, 175);
  --BgColor: #EAE3EA;
  --formColor: #c199c1;
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem; */
  --primaryColor: #A7B3A5;
  --primaryHover: #758473; 
  --secondaryColor: #B56357; 
  --paragraphColor: rgb(175, 131, 127);
  --BgColor: #EAE3EA; 
  --formColor: #c199c1; 
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem;
}

.home .homeContainer {
  padding: 4rem 1.5rem;
}
.home .homeContainer .introText {
  font-size: 1.3rem;
  color: var(--primaryColor);
  font-weight: 600;
  letter-spacing: 1px;
}
.home .homeContainer .title {
  font-size: 3.3rem;
  font-weight: 600;
  color: var(--textColor);
  padding: 0.7rem 0;
}
.home .homeContainer .homeParagraph {
  display: block;
  font-weight: 400;
  color: var(--secondaryColor);
  line-height: 2rem;
  padding: 1.5rem 0;
}
.home .homeContainer .subTitle {
  padding: 0.6rem 1rem;
  color: var(--paragraphColor);
  padding: 1rem 0;
  max-width: 600px;
}
.home .homeContainer .lowerHomeSection .contactBtn {
  padding: 0.6rem 1rem;
  background: var(--primaryColor);
  color: var(--BgColor);
  border: none;
  outline: none;
}
.home .homeContainer .lowerHomeSection .contactBtn:hover {
  background: var(--primaryHover);
}
.home .homeContainer .lowerHomeSection .contactBtn a {
  font-size: 18px;
  font-weight: 600;
  gap: 0.5rem;
  align-items: center;
  color: var(--BgColor);
}
.home .homeContainer .lowerHomeSection {
  padding-top: 1rem;
  display: flex;
  align-items: center;
}
.home .homeContainer .lowerHomeSection {
  font-size: 1rem;
  color: var(--primaryColor);
}
.home .homeContainer .lowerHomeSection .icon {
  font-size: 1.3rem;
  margin-left: 0.3rem;
}

@keyframes move {
  0% {
    transform: translateY(5px);
  }
  25% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  75% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(5px);
  }
}
.home .leftIcons, .home .rightEmail {
  display: none;
}

@media screen and (min-width: 480px) {
  .home .homeContainer {
    padding: 5rem 1.5rem;
  }
  .home .homeContainer .introText {
    font-size: 1.5rem;
  }
  .home .homeContainer .title {
    font-size: 3.5rem;
  }
  .home .homeContainer .subTitle {
    font-size: 1.5rem;
  }
  .home .homeContainer .homeParagraph {
    font-size: 1.8rem;
  }
  .home .homeContainer .lowerHomeSection .contactBtn {
    padding: 0.8rem 1.5rem;
  }
  .home .homeContainer .lowerHomeSection .contactBtn a {
    font-size: 22px;
  }
  .home .homeContainer {
    padding-top: 1.5rem;
  }
  .home .homeContainer .icon {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 770px) {
  .home .homeContainer {
    padding: 5rem 1.5rem;
    width: 70%;
    margin: auto;
  }
  .home .leftIcons, .home .rightEmail {
    display: block;
    position: fixed;
  }
  .home .leftIcons .icon, .home .rightEmail .icon {
    color: var(--secondaryColor);
  }
  .home .leftIcons a, .home .rightEmail a {
    font-weight: 600;
    color: var(--secondaryColor);
  }
  .home .leftIcons .line, .home .rightEmail .line {
    margin: auto;
    content: "";
    background: var(--secondaryColor);
    height: 100px;
    width: 1px;
  }
  .home .leftIcons {
    left: 5%;
    bottom: 0;
  }
  .home .leftIcons .socials {
    gap: 1rem;
  }
  .home .leftIcons .socials a {
    color: var(--textColor);
  }
  .home .leftIcons .socials a:hover {
    color: var(--primaryColor);
  }
  .home .rightEmail {
    right: 0;
    bottom: 0;
  }
  .home .rightEmail .emailAddress {
    transform: rotate(90deg) translate(-90px, 0px);
  }
  .home .rightEmail .emailAddress a {
    letter-spacing: 1px;
  }
}
@media screen and (min-width: 1240px) {
  .home .homeContainer {
    padding: 6rem;
  }
  .home .homeContainer .introText {
    font-size: 1.5rem;
  }
  .home .homeContainer .title {
    font-size: 5rem;
  }
  .home .homeContainer .subTitle {
    font-size: 1.8rem;
  }
  .home .homeContainer .homeParagraph {
    line-height: 2.5rem;
    font-size: 2.4rem;
  }
  
}/*# sourceMappingURL=Home.css.map */