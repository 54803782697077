:root{
    --primaryColor: #A7B3A5;
    --primaryHover: #758473;
    --secondaryColor: #B56357;
    --paragraphColor: rgb(127, 127, 175);
    --BgColor: #EAE3EA;
    --formColor: #c199c1;
    --projectCardColor: #a15347;
    --textColor: #743026;
    --headerHeight: 3rem;
}

.projects{
    .projectContainer{
        margin-top: 1rem;
        justify-content: space-between;
        grid-template-columns: repeat(1,1fr);
        gap: 1rem;

        .singleProject{
            display: grid;
            gap: .5rem;
            padding: 1.5rem 1rem;
            border-radius: 10px;
            height: 100%;
            background: var(--projectCardColor);

            .externalLinks{
                width: 100%;
                justify-content: space-between;

                .youtubeIcon, .githubIcon{
                    a{
                        color: var(--primaryColor);
                        &:hover{
                            color: var(--primaryHover);
                        }
                    }
                }
            }

            &:hover{
                transform: translateY(-7px);
                border-bottom: 2px solid var(--primaryColor);
            }

            .imgDiv{
                height: 150px;
                width: auto;
                border-radius: 5px;
                overflow: hidden;
                cursor: pointer;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                }
            }

            .projectTitle{
                display: flex;
                font-weight: 600;
                color: var(--textColor);
            }

            .desc{
                font-weight: 600;
                color: var(--secondaryColor);
            }

            .technologies{
                gap: .5rem;
                justify-content: flex-end;
                small{
                    color: var(--secondaryColor);
                    font-weight: 600;
                }
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .projects{
        .projectContainer{
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media screen and (min-width: 769px) {
    .projects{
        width: 70%;
        margin: auto;
        .projectContainer{
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media screen and (min-width: 1150px) {
    .projects{
        .projectContainer{
            grid-template-columns: repeat(3, 1fr);

            .singleProject{
                .desc{
                    font-size: 17px;

                    .technologies{
                        small{
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1240px){
    .projects{
        padding-left: 2rem;
        padding-right: 2rem;
    }
}