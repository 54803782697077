:root{
    --primaryColor: #A7B3A5;
    --primaryHover: #758473;
    --secondaryColor: #B56357;
    --paragraphColor: rgb(127, 127, 175);
    --BgColor: #EAE3EA;
    --formColor: #c199c1;
    --projectCardColor: #a15347;
    --textColor: #743026;
    --headerHeight: 3rem;
    
}

.home{
    .homeContainer{
        padding: 4rem 1.5rem;
        .introText{
            font-size: 1.3rem;
            color: var(--primaryColor);
            font-weight: 600;
            letter-spacing: 1px;
        }

        .title{
            font-size: 3.3rem;
            font-weight: 600;
            color: var(--textColor);
            padding: .7rem 0;
        }

        .homeParagraph{
            display: block;
            font-size: 1.8rem;
            font-weight: 600;
            color: var(--secondaryColor);
            line-height: 2rem;
            padding: .5rem 0;
        }

        .subTitle{
            font-size: 1.3rem;
            padding: .6rem 1rem;
            color: var(--paragraphColor);
            padding: 1rem 0;
            max-width: 600px;
        }

        .lowerHomeSection{
            .contactBtn{
                padding: .6rem 1rem;
                background: var(--primaryColor);
                color: var(--BgColor);
                border: none;
                outline: none;
                &:hover{
                    background: var(--primaryColor);
                }

                a{
                    font-size: 18px;
                    font-weight: 600;
                    gap: .5rem;
                    align-items: center;
                    color: var(--BgColor);
                }
            }

        }
    }

    .leftIcons, .rightEmail{
        display: none;
    }
}

@media screen and (min-width: 480px){
    .home{
        .homeContainer{
            padding: 5rem 1.5rem;
            .introText{
                font-size: 1.5rem;
            }

            .title{
                font-size: 3.5rem;
            }

            .subTitle{
                font-size: 2.1rem;
            }

            .homeParagraph{
                font-size: 1.5rem;
            }

            .lowerHomeSection{
                .contactBtn{
                    padding: .8rem 1.5rem;
                    a{
                        font-size: 22px;
                    }
                }
            }

        }
    }
}

@media screen and (min-width: 770px){
    .home{
        .homeContainer{
            padding: 5rem 1.5rem;
            width: 70%;
            margin: auto;
        }

        .leftIcons, .rightEmail{
             display: block;
             position: fixed;
            
            .icon{
                color: var(--secondaryColor);
            }

            a{
                font-weight: 600;
                color: var(--secondaryColor);
            }

            .line{
                margin: auto;
                content: '';
                background: var(--secondaryColor);
                height: 100px;
                width: 1px;
            }
        }

        .leftIcons{
            left: 5%;
            bottom: 0;
            .socials{
                gap: 1rem;

                a{
                    color: var(--textColor);
                    &:hover{
                        color: var(--primaryColor);
                    }
                }
            }
        }

        .rightEmail{
            right: 0;
            bottom: 0;
            .emailAddress{
                transform: rotate(90deg) translate(-90px, 0px);

                a{
                    letter-spacing: 1px;
                }
            }
        }
    }
}

@media screen and (min-width: 1240px){
    .home{
        .homeContainer{
            padding: 6rem;
            
            .introText{
                font-size: 1.5rem;
            }

            .title{
                font-size: 5rem;
            }

            .subTitle{
                line-height: 2.5rem;
                font-size: 2.4rem;
            }

            .homeParagraph{
                font-size: 1.8rem;
            }
        }
    }
}