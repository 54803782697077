:root{
    --primaryColor: #A7B3A5;
    --primaryHover: #758473;
    --secondaryColor: #B56357;
    --paragraphColor: rgb(127, 127, 175);
    --BgColor: #EAE3EA;
    --formColor: #c199c1;
    --projectCardColor: #a15347;
    --textColor: #743026;
    --headerHeight: 3rem;
}

.about{
    .sectionContent{
        .textSection{
            h4{
                color: var(--secondaryColor);
                font-size: 1.1rem;
                max-width: 100%;
                letter-spacing: 1px;
                font-weight: 500;
                margin: auto;
            }

            .aboutBtn{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2rem;
                background: var(--primaryColor);
                width: max-content;
                padding: 10px 15px;

                &:hover{
                    background: var(--primaryHover);
                }

                a{
                    color: var(--projectCardColor);
                    font-size: 1.2rem;
                    font-weight: 600;
                    cursor: pointer;
                    gap: .5rem;
                }

                a:hover{
                    color: var(--textColor);
                }
            }
        }

        .aboutImgDiv{
            position: relative;
            width: 250px;
            height: 250px;
            margin: 2rem auto 3rem;
            border-radius: 50%;
            overflow: hidden;
            display: grid;
            border: 2px dashed var(--primaryColor);

            .aboutImg{
                width: 215px;
                object-fit: cover;
                align-self: center;
                justify-self: center;
            }
        }

        .aboutImgDiv::before{
            content: 'Hover Me';
            font-size: 1.6rem;
            color: var(--BgColor);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Inconsolata', monospace;
            justify-self: center;
            align-self: center;
            background: var(--primaryColor);
            mix-blend-mode: screen;
            position: absolute;
            height: 97%;
            width: 97%;
            border-radius: 50%;
            overflow: hidden;
            transition: all 0.3s;
        }

        .aboutImgDiv:hover.aboutImgDiv::before{
            height: 0%;
        }
    }
} 

@media screen and (min-width: 769px){
    .about{
        width: 70%;
        margin: auto;
    }
}

@media screen and (min-width: 960px){
    .about{
        .sectionContent{
            grid-template-columns: repeat(2, 1fr);
            justify-content: center;
            margin: auto;
        }
    }
}

@media screen and (min-width: 1240px){
    .about{
        .sectionContent{
            .textSection{
                h4{
                    font-size: 1.2rem;
                }
            }
        }
    }
}

