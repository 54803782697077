:root {
  /* --primaryColor: #A7B3A5;
  --primaryHover: #758473;
  --secondaryColor: #B56357;
  --paragraphColor: rgb(127, 127, 175);
  --BgColor: #EAE3EA;
  --formColor: #c199c1;
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem; */
  --primaryColor: #A7B3A5;
  --primaryHover: #758473; 
  --secondaryColor: #B56357; 
  --paragraphColor: rgb(175, 131, 127);
  --BgColor: #EAE3EA; 
  --formColor: #c199c1; 
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem;
}

.about .sectionContent .textSection h4 {
  color: var(--secondaryColor);
  font-size: 1.1rem;
  max-width: 100%;
  letter-spacing: 1px;
  font-weight: 500;
  margin: auto;
}
.about .sectionContent .textSection .aboutBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  background: var(--primaryColor);
  width: -moz-max-content;
  width: max-content;
  padding: 10px 15px;
}
.about .sectionContent .textSection .aboutBtn:hover {
  background: var(--primaryHover);
}
.about .sectionContent .textSection .aboutBtn a {
  color: var(--BgColor);
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  gap: 0.5rem;
}
.about .sectionContent .textSection .aboutBtn a:hover {
  color: var(--textColor);
}
.about .sectionContent .aboutImgDiv {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0px auto;
  border-radius: 50%;
  overflow: hidden;
  display: grid;
  border: 8px double var(--primaryColor);
}
/* .about .sectionContent .aboutImgDiv .aboutImg {
  width: 215px;
  -o-object-fit: cover;
     object-fit: cover;
  align-self: center;
  justify-self: center;
}
.about .sectionContent .aboutImgDiv::before {
  content: "Hover Me";
  font-size: 1.6rem;
  color: var(--BgColor);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inconsolata", monospace;
  justify-self: center;
  align-self: center;
  background: var(--primaryColor);
  mix-blend-mode: screen;
  position: absolute;
  height: 97%;
  width: 97%;
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.3s;
}
.about .sectionContent .aboutImgDiv:hover.aboutImgDiv::before {
  height: 0%;
} */

@media screen and (min-width: 769px) {
  .about {
    width: 70%;
    margin: auto;
  }
}
@media screen and (min-width: 960px) {
  .about .sectionContent {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin: auto;
  }
}
@media screen and (min-width: 1240px) {
  .about .sectionContent .textSection h4 {
    font-size: 1.2rem;
  }
}/*# sourceMappingURL=About.css.map */