:root{
    --primaryColor: #A7B3A5;
    --primaryHover: #758473;
    --secondaryColor: #B56357;
    --paragraphColor: rgb(127, 127, 175);
    --BgColor: #EAE3EA;
    --formColor: #c199c1;
    --projectCardColor: #a15347;
    --textColor: #743026;
    --headerHeight: 3rem;
}

.header{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: var(--headerHeight);
    padding: 2rem;
    z-index: 1000;
    background: var(--projectCardColor);

    .logo a{
        color: var(--textColor);
        font-weight: 600;
        cursor: pointer;
    }
    
    @media screen and (max-width: 768px) {
        .navBar{
            background: var(--projectCardColor);
            position: absolute;
            height: max-content;
            width: 80%;
            border-radius: 1rem;
            padding: 1rem;
            top: -100rem;
            left: 50%;
            transform: translate(-50%);
            box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
            z-index: 1000;
    
            .navLists{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                margin: auto;
                padding: 1rem 0;
    
                .navItem{
                    padding: 1rem 0;
    
                    .navLink{
                        color: var(--textColor);
                        font-size: 1.2rem;
                        font-weight: 600;
                        &:hover{
                            color: var(--primaryColor);
                        }
    
                        .headerNumber{
                            color: var(--primaryHover);
                        }
                    }
                }
    
                .btn{
                    margin-top: 1rem;
                    
                    a{
                        color: var(--textColor);
                    }
                }
            }
    
            .closeNavBar{
                position: absolute;
                top: 1rem;
                right: 1.5rem;
    
                &:hover{
                    .icon{
                        color: var(--primaryColor);
                    }
                }
            }
    
        }
    
        .activeNavBar{
            top: 6rem;
        }
    }
}

.activeHeader{
    background-color: var(--projectCardColor);
    box-shadow: 0 2px 4px rgba(1, 15, 29, 0.904);
}

@media screen and (min-width: 769px){
    .toggleNavBar, .closeNavBar{
        display: none;
    }

    .header{
        padding: 2.5rem 2rem;

        .navBar{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            padding: 0 1rem;

            .navItem{
                padding: 0 1rem;

                .navLink{
                    color: var(--textColor);
                    font-size: 1.2rem;
                    font-weight: 600;
                    &:hover{
                        color: var(--primaryColor);
                    }

                    .headerNumber{
                        color: var(--primaryHover);
                    }
                }
            }

            .btn{
                margin-left: 1rem;
                a{
                    color: var(--primaryColor);
                }
            }
        }
    }
}

@media screen and (min-width: 960px){
   .header{
        padding: 2.5rem 5rem;

        .navBar{
            .navLists{
                padding: 0 2rem;
                
                .navItem{
                    padding: 0 2rem;
                }
            }
       }
   } 

  
}