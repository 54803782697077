:root {
  /* --primaryColor: #A7B3A5;
  --primaryHover: #758473;
  --secondaryColor: #B56357;
  --paragraphColor: rgb(127, 127, 175);
  --BgColor: #EAE3EA;
  --formColor: #c199c1;
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem; */
  --primaryColor: #A7B3A5;
  --primaryHover: #758473; 
  --secondaryColor: #B56357; 
  --paragraphColor: rgb(175, 131, 127);
  --BgColor: #EAE3EA; 
  --formColor: #c199c1; 
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem;
}

.contact .contactContainer {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}
.contact .contactContainer {
  justify-content: center;
}
.contact .contactContainer .form {
  width: 90%;
  justify-content: center;
  margin: auto;
}
.contact .contactContainer .form h3 {
  text-align: center;
  color: var(--secondaryColor);
}
.contact .contactContainer .form form {
  width: 100%;
  text-align: center;
}
.contact .contactContainer .form form input, .contact .contactContainer .form form textarea {
  height: 1rem;
  width: 90%;
  padding: 1.5rem 0.5rem;
  border-radius: 10px;
  background: none;
  border: 2px solid var(--secondaryColor);
  margin: 0.5rem 0;
  outline: none;
  color: var(--textColor);
  font-weight: 600;
}
.contact .contactContainer .form form input::-moz-placeholder, .contact .contactContainer .form form textarea::-moz-placeholder {
  color: var(--secondaryColor);
  opacity: 0.5;
}
.contact .contactContainer .form form input::placeholder, .contact .contactContainer .form form textarea::placeholder {
  color: var(--secondaryColor);
  opacity: 0.5;
}
.contact .contactContainer .form form textarea {
  height: 150px;
}
.contact .contactContainer .form form .formBtn {
  display: block;
  margin: auto;
  width: 90%;
  border: none;
  padding: 1rem 0;
  border-radius: 10px;
  font-size: 18px;
  background: var(--primaryColor);
  color: var(--BgColor);
  font-weight: 600;
  cursor: pointer;
}
.contact .contactContainer .form form .formBtn:hover {
  background: var(--primaryHover);
}

@media screen and (min-width: 640px) {
  .cards {
    justify-content: center;
    /* grid-template-columns: repeat(2, 1fr); */
  }
}
/* @media screen and (min-width: 1150px) {
  .contact .contactContainer {
    align-items: flex-start !important;
  }
}
@media screen and (min-width: 1240px) {
  .contact {
    padding-left: 2rem;
    padding-right: 2rem;
  }
} */
/*# sourceMappingURL=Contact.css.map */