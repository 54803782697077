:root {
  /* --primaryColor: #A7B3A5;
  --primaryHover: #758473;
  --secondaryColor: #B56357;
  --paragraphColor: rgb(127, 127, 175);
  --BgColor: #EAE3EA;
  --formColor: #c199c1;
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem; */
  --primaryColor: #A7B3A5;
  --primaryHover: #758473; 
  --secondaryColor: #B56357; 
  --paragraphColor: rgb(175, 131, 127);
  --BgColor: #EAE3EA; 
  --formColor: #c199c1; 
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem;
}

.skills .skillsContainer {
  gap: 2rem 0;
  padding: 1rem 0;
}
.skills .skillsContainer .skillGroup .groupTitle {
  color: var(--textColor);
}
.skills .skillsContainer .skillGroup .groupTitle .subTitle {
  display: block;
  padding: 0.3rem 0 1rem;
  color: var(--secondaryColor);
  font-weight: 600;
}
.skills .skillsContainer .skillGroup .generalSkills {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.skills .skillsContainer .skillGroup .generalSkills .singleSkill {
  display: grid;
  align-items: center;
  justify-content: center;
}
.skills .skillsContainer .skillGroup .generalSkills .singleSkill .iconBox {
  position: relative;
  height: 70px;
  width: 50px;
  background: var(--primaryColor);
  /* background: var(--secondaryColor); */
  border: 2px solid var(--projectCardColor);
  border-radius: 3rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 500;
}
.skills .skillsContainer .skillGroup .generalSkills .singleSkill .iconBox .icon {
  font-size: 28px;
  z-index: 500;
}
.skills .skillsContainer .skillGroup .generalSkills .singleSkill .iconBox::before {
  content: "";
  height: 0;
  width: 100%;
  /* background: var(--primaryHover); */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.2s;
}
.skills .skillsContainer .skillGroup .generalSkills .singleSkill .iconBox::after {
  content: "";
  height: 0;
  width: 100%;
  background: var(--BgColor);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.5s;
}
.skills .skillsContainer .skillGroup .generalSkills .singleSkill .iconBox:hover {
  color: var(--textColor);
  border: 2px solid var(--primaryColor);
}
.skills .skillsContainer .skillGroup .generalSkills .singleSkill .iconBox:hover .icon {
  color: var(--primaryColor);
}
.skills .skillsContainer .skillGroup .generalSkills .singleSkill .iconBox:hover::before, .skills .skillsContainer .skillGroup .generalSkills .singleSkill .iconBox:hover::after {
  height: 100%;
}
.skills .skillsContainer .skillGroup .generalSkills .singleSkill .skillName {
  padding-top: 0.3rem;
  text-align: center;
  font-weight: 600;
  color: var(--secondaryColor);
}

@media screen and (min-width: 480px) {
  .skills .skillsContainer .skillGroup .generalSkills {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 640px) {
  .skills .skillsContainer {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    align-items: flex-start;
    margin: auto;
    justify-content: center;
  }
  .skills .skillsContainer .skillGroup .generalSkills {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 769px) {
  .skills {
    width: 70%;
    margin: auto;
  }
  .skills .skillsContainer {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    align-items: flex-start;
  }
  .skills .skillsContainer .skillGroup .generalSkills {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1240px) {
  .skills .skillsContainer {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    align-items: flex-start;
  }
  .skills .skillsContainer .skillGroup .generalSkills {
    grid-template-columns: repeat(2, 1fr);
    padding-right: 5rem;
    row-gap: 1.5rem;
  }
  .skills .skillsContainer .skillGroup .generalSkills .singleSkill {
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
  }
}/*# sourceMappingURL=Skills.css.map */