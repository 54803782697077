:root {
  /* --primaryColor: #A7B3A5;
  --primaryHover: #758473;
  --secondaryColor: #B56357;
  --paragraphColor: rgb(127, 127, 175);
  --BgColor: #EAE3EA;
  --formColor: #c199c1;
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem; */
  --primaryColor: #A7B3A5;
  --primaryHover: #758473; 
  --secondaryColor: #B56357; 
  --paragraphColor: rgb(175, 131, 127);
  --BgColor: #EAE3EA; 
  --formColor: #c199c1; 
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem;
}

.footer {
  width: 100%;
  background-color: var(--projectCardColor);
  color: var(--BgColor);
  margin-top: 1rem;
  padding: 1rem 0;
  text-align: center;
}/*# sourceMappingURL=Footer.css.map */