@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300;400;600&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Advent Pro', sans-serif;
    transition: .3s ease;
}

:root{
    --primaryColor: #A7B3A5;
    --primaryHover: #758473; 
    --secondaryColor: #B56357; 
    --paragraphColor: rgb(175, 131, 127);
    --BgColor: #EAE3EA; 
    --formColor: #c199c1; 
    --projectCardColor: #a15347;
    --textColor: #743026;
    --headerHeight: 3rem;
}

html {
    scroll-behavior: smooth;
}

a{
    text-decoration: none;
}

li{
    list-style: none;
}

.flex{
    display: flex;
    align-content: center;
}

.grid{
    display: grid;
    align-content: center;
}

input, textarea {
    font-size: 100%;
}

.section{
    padding: 4rem 0 2rem;
}

.container{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

img{
    height: auto;
    width: 100%;
}

.icon{
    font-size: 25px;
    cursor: pointer;
}

.btn{
    border: 2px solid var(--primaryHover);
    padding: 0.5rem 1.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    position: relative;
    color: var(--primaryColor);
    background: transparent;
}

.btn::before{
    content: '';
    height: 100%;
    width: 0%;
    background: var(--primaryColor);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: .2s;
}

.btn::after{
    content: '';
    height: 100%;
    width: 0%;
    background: var(--BgColor);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: .5s;
}

.btn:hover::before, .btn:hover::after{
    width: 100%;
}

.navLink{
    position: relative;
}

.navLink::before{
    content: '';
    position: absolute;
    background: var(--primaryHover);
    height: 2px;
    width: 0%;
    bottom: -3px;
    left: 0;
    transition: 0.3s;
}   

.navLink::after{
    content: '';
    position: absolute;
    background: var(--projectCardColor);
    height: 2px;
    width: 0%;
    bottom: -3px;
    left: 0; 
    transition: 0.8s;
}   

.sectionTitle{
    padding: 1rem  0;
    font-size: 2rem;
    color: var(--textColor);
    display: flex;
    align-content: center;
}

.titleNumber{
    color: var(--primaryColor);
    margin: 0 0.6rem;
    font-weight: 600;
}

.titleText{
    position: relative;
    width: max-content;
}   

.underline{
    position: absolute;
    width: 100%;
    height: 3px;
    top: 35px;
    left: 0;
    border-radius: 10px;
    background: var(--secondaryColor);
    overflow: hidden;
}

.underline span{
    top: 0;
    left: 10px;
    height: 100%;
    width: 10px;
    border-radius: 10px;
    position: absolute;
    background: var(--textColor);
    animation: moveLine 2s linear infinite;
}

@keyframes moveLine{
    0%{
        left: -20px;
    }

    100%{
        left: 100px;
    }
}

body{
    background: var(--BgColor);
    color: var(--textColor);
}

::selection{
    background-color: var(--primaryColor);
    color: white;
}

::-webkit-scrollbar{
    width: 10px;
    background: var(--projectCardColor);
}

::-webkit-scrollbar-thumb{
    background: var(--primaryHover);
    border-radius: 10px;
}

@media screen and (min-width: 769px){
    .container{
        width: 70%;
        margin: auto;
    }
}

@media screen and (min-width: 960px){
    .section{
        padding: 6rem 0 4rem;
    }
}

@media screen and (min-width: 1240px){
    .section{
        padding: 6rem 0 4rem;
    }

    .container{
        width: 70%;
        margin: auto;
    }
}

@media screen and (min-width: 1440px){
    body{
        zoom: 1;
    }
}

@media screen and (min-width: 2560px){
    body{
        zoom: 1.7;
    }
}