:root {
  /* --primaryColor: #A7B3A5;
  --primaryHover: #758473;
  --secondaryColor: #B56357;
  --paragraphColor: rgb(127, 127, 175);
  --BgColor: #EAE3EA;
  --formColor: #c199c1;
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem; */
  --primaryColor: #A7B3A5;
  --primaryHover: #758473; 
  --secondaryColor: #B56357; 
  --paragraphColor: rgb(175, 131, 127);
  --BgColor: #EAE3EA; 
  --formColor: #c199c1; 
  --projectCardColor: #a15347;
  --textColor: #743026;
  --headerHeight: 3rem;
}

.projects .projectContainer {
  margin-top: 1rem;
  justify-content: space-between;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}
.projects .projectContainer .singleProject {
  display: grid;
  gap: 0.5rem;
  padding: 1.5rem 1rem;
  border-radius: 10px;
  height: 100%;
  background: var(--projectCardColor);
}
.projects .projectContainer .singleProject .externalLinks {
  width: 100%;
  justify-content: space-between;
}
.projects .projectContainer .singleProject .externalLinks .youtubeIconAndLiveLink a, 
.projects .projectContainer .singleProject .externalLinks .githubIcon a,
.projects .projectContainer .singleProject .projectTitle a 
{
  color: var(--primaryColor);
}
.projects .projectContainer .singleProject .externalLinks .youtubeIconAndLiveLink a:hover, 
.projects .projectContainer .singleProject .externalLinks .githubIcon a:hover,
.projects .projectContainer .singleProject .projectTitle a:hover 
{
  color: var(--primaryHover);
}
.projects .projectContainer .singleProject:hover {
  transform: translateY(-7px);
  border-bottom: 2px solid var(--primaryColor);
}
.projects .projectContainer .singleProject .imgDiv {
  height: 150px;
  width: auto;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.projects .projectContainer .singleProject .imgDiv img {
  height: 100%;
  width: 100%;
  /* -o-object-fit: cover; */
  object-fit: cover;
  border-radius: 5px;
}
.projects .projectContainer .singleProject .projectTitle {
  display: flex;
  align-items: center;
  display: inline;
  font-weight: 600;
  color: var(--primaryColor);
  /* color: red; */
}
.projects .projectContainer .singleProject .desc {
  font-weight: 600;
  color: var(--BgColor);
}
.projects .projectContainer .singleProject .technologies {
  gap: 0.5rem;
  justify-content: flex-end;
}
.projects .projectContainer .singleProject .technologies small {
  color: var(--primaryColor);
  font-weight: 600;
}

@media screen and (min-width: 600px) {
  .projects .projectContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 769px) {
  .projects {
    width: 70%;
    margin: auto;
  }
  .projects .projectContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1150px) {
  .projects .projectContainer {
    grid-template-columns: repeat(3, 1fr);
  }
  .projects .projectContainer .singleProject .desc {
    font-size: 17px;
  }
  .projects .projectContainer .singleProject .desc .technologies small {
    font-size: 15px;
  }
}
@media screen and (min-width: 1240px) {
  .projects {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}/*# sourceMappingURL=Projects.css.map */